<template>
    <div class="pd20x flex1">
        <addIndexModel :visible="visible" @close="visible = false" :data="currentItem"></addIndexModel>
        <div class="h100pct ofA">
            <div class="radio-line" style="padding:10px 20px 0">
                <div class="radio-line-item" v-for="(item, index) in radioList" :key="index" :class="{active: index == currentIndex}" @click="currentIndex = index;degreeIndex = 0">{{item}}</div>
            </div>
             <div class="filter" style="background:#fff;padding:10px 20px" v-if="currentIndex == 1">
                <div>
                    <div class="radioGroup">
                        <div class="radioItem" v-for="(item, index) in degreeList" :key="index" :class="{active: index == degreeIndex}" @click="degreeIndex = index">{{item}}</div>
                    </div>
                </div>
            </div>
            <div class="filter" style="background:#Fff;padding-top:15px">
                <div>{{radioList[currentIndex]}}分类（5条） <span style="color:#888; font-size:13px">上下拖动课排序，此排序顺序对应师探保过班前端课程类型展示顺序；已关闭课程类型不参与顺序</span></div>
                <div>
                    <!-- <a-select default-value="0" style="width: 120px" @change="handleChange" class="mr20px">
                        <a-select-option value="0">
                            全部
                        </a-select-option>
                        <a-select-option value="1">
                            已解决
                        </a-select-option>
                        <a-select-option value="2">
                            未解决
                        </a-select-option>
                    </a-select> -->
                    <a-button type="primary" @click="()=>{ currentItem = null ; visible = true }">添加</a-button>
                </div>
            </div>
            <!-- 考证书 -->
            <div class="dragTable" v-if="currentIndex == 0">
                <div class="dragHeader">
                    <div class="iconTh"></div>
                    <div class="flex1">分类名称</div>
                    <div class="flex1">课程包名称</div>
                    <div class="flex1">启用状态</div>
                    <div class="operate">操作</div>
                </div>
                <draggable handle=".dragIcon" :list="result">
                    <div class="dragBody" v-for="(item, index) in result" :key="index">
                        <div class="iconTh">
                            <i class="dragIcon"></i>
                        </div>
                        <div class="flex1">{{item.title}}</div>
                        <div class="flex1" style="color:#ff5f4e">{{item.link}}</div>
                        <div class="flex1"><a-switch default-checked /></div>
                        <div class="operate">
                            <i class="z-icon edit" @click="()=>{currentItem = item ; visible = true}"></i>
                            <i class="z-icon delete"></i>
                        </div>
                    </div>
                </draggable>
            </div>
            <!-- 升学历 -->
             <div class="dragTable" v-if="currentIndex == 1">
                <div class="dragHeader">
                    <div class="iconTh"></div>
                    <div class="flex1">标题</div>
                    <div class="flex1">年限</div>
                    <div class="flex1">课程包</div>
                    <div class="flex1">启用状态</div>
                    <div class="operate">操作</div>
                </div>
                <draggable handle=".dragIcon" :list="result">
                    <div class="dragBody" v-for="(item, index) in result" :key="index">
                        <div class="iconTh">
                            <i class="dragIcon"></i>
                        </div>
                        <div class="flex1">{{item.title}}</div>
                        <div class="flex1"></div>
                        <div class="flex1" style="color:#70b2e2">{{item.link}}</div>
                        <div class="flex1"><a-switch default-checked /></div>
                        <div class="operate">
                            <i class="z-icon edit" @click="()=>{currentItem = item ; visible = true}"></i>
                            <i class="z-icon delete"></i>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
    </div>
</template>
<script>
import addIndexModel from '@/components/exam/addIndexModel'
export default {
    name: 'indexModel',
    components: { addIndexModel },
    data(){
        this.radioList = ['考证书']
        this.degreeList = ['公共课', '高起专', '专升本', '自学考试', '考研双证' ]
        return{
            currentIndex: 0,
            degreeIndex: 0,
            result: [
                {
                    id:1,
                    title: '会计类',
                    link:132646465,
                }, {
                    id:2,
                    title: '师范类',
                    link:13264646465464565,
                }
            ],
            visible: false,
            currentItem: null
        }
    },
    methods:{
        handleChange(){

        },
        onSelectChange(){

        },
        addItem(){
            this.visible = true;
            this.currentItem = null
        },
        deleteItem(){
             this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    console.log('yes')
                },
            });
        },
        hideModal(){

        }
    }
}
</script>
<style lang="less" scoped>

</style>