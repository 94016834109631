<template>
     <a-modal
        :title="title"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="$emit('close')"
         @ok="handleSubmit"
        destroyOnClose
    >
      <a-form
        :form="form"
        v-bind="formItemLayout"
    >
        <a-form-item label="分类名称">
            <a-input  v-decorator="[ 'link',{ rules: [{ required: true, message: '请填写分类名称' }] },]" placeholder="请输入链接" />
        </a-form-item>
        <a-form-item label="链接">
            <a-input  v-decorator="[ 'link', ]" placeholder="请输入链接" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
export default {
    name: 'addIndexModel',
    props: [ 'visible', 'data' ],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch:{
        visible(val){
            if(val){
                if(this.data){
                    this.title = "编辑轮播图"
                }else{
                    this.title = "新增轮播图"
                }
            }
        }
    },
    data(){
        return{
            confirmLoading: false,
            loading: false,
            title: '',
            imageUrl:'',
            formItemLayout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 18 },
            },
        }
    },
    methods:{
        handleSubmit(){
            this.form.validateFields((err, values) => {
                if(err) return;
            })
        },
        beforeUpload(){

        },
        handleChange(){

        }
    }
}
</script>